import React, {useEffect, useState} from 'react'
import "./styles.css"

import {MainListItems, SecondaryListItems} from "./LeftMenu";
import {
    Container,
    CssBaseline, IconButton,
    List, Paper,
    styled,
    Toolbar, Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import BottomNavigationMenu from "../BottomNavigationMenu";
import 'prevent-pull-refresh';
import {Settings} from "@mui/icons-material";
import {FloatingWhatsApp} from 'react-floating-whatsapp'
import ProfilePopup from "./profile_popup/ProfilePopup";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    color: '#673ab7',
    backgroundColor: '#ffffff',
    zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer)(
    ({theme}) => ({
        '& .MuiDrawer-paper': {
            whiteSpace: 'nowrap',
            width: drawerWidth,
            boxSizing: 'border-box',
        },
        '& .styles-module_whatsappButton': {
            animation: null,
        }
    }),
);

export default function NavBar(props) {
    const [width, setWidth] = useState(window.innerWidth);
    const logo = require('./logo192.png');

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)

        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    function renderNavigation() {
        const isMobile = width < 1280;
        if (!isMobile) {
            return <Drawer variant="permanent">
                <Toolbar/>
                <Box>
                    <List component="nav">
                        <MainListItems/>
                    </List>
                </Box>
                <Box sx={{flexGrow: 1}}/>
                <Box>
                    <List component="nav" sx={{flex: 1}}>
                        <SecondaryListItems/>
                    </List>
                </Box>
            </Drawer>
        }
        return <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000}} elevation={2}>
            <BottomNavigationMenu/>
        </Paper>
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar elevation={1} position="fixed">
                <Toolbar variant="dense" sx={{flexGrow: 1}}>
                    <Box sx={{flexGrow: 1}}/>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{align: "center"}}
                    >
                        🦉 4Docentes
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: 'flex'}}>
                        <Tooltip title="Ajustes">
                            <IconButton size="large" color="inherit" aria-label="Ajustes" href='/ajustes/'>
                                <Settings color="primary"/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    color: 'white',
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23003A75' fill-opacity='0.1'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                }}
            >
                <Toolbar/>
                <Container
                    maxWidth="lg"
                    sx={{mt: 1, mb: 2, pl: 0.5, pr: 0.5}}>
                    {props.children}
                </Container>
                <Toolbar/>
                {renderNavigation()}
                <FloatingWhatsApp buttonStyle={{marginBottom: 35, height: 45, width: 45, animation: null}}
                                  chatboxHeight={400} avatar={logo} phoneNumber="+34661024314"
                                  accountName="Francis de 4Docentes" statusMessage="Disponible"
                                  chatMessage="¡Hola! Soy Francis. Déjame un mensaje si necesitas cualquier cosa. ¡Recuerda que no soy una inteligencia artificial!"
                                  placeholder="Escribe un mensaje..." messageDelay={0} allowClickAway allowEsc
                                  notification={false}/>
                <ProfilePopup/>
            </Box>
        </Box>
    );
}
