import React, {useState} from "react";
import Box from "@mui/material/Box";
import {Autocomplete, DialogActions, FormControl, Grid, InputLabel, Select} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MarketingService from "../../services/MarketingService";
import ErrorMessage from "../genericui/feedback/ErrorMessage";

const MarketingProfile = (props) => {
    const {onSave} = props;
    const [formData, setFormData] = useState({
        centro: "",
        etapa_educativa: "",
        pais: "España",
        origen: "",
        situacion: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    function handleFormFieldChange(event) {
        let currentFormData = formData;
        currentFormData[event.target.id] = event.target.value;
        setFormData(currentFormData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        const formContent = {
            centro: formData.centro,
            etapa_educativa: formData.etapa_educativa,
            pais: formData.pais.label,
            origen: formData.origen,
            situacion: formData.situacion,
        }
        MarketingService.createMarketingProfile(formContent).then(
            _ => onSave()
        ).catch(_ => setIsErrorMessageVisible(true)
        ).finally(() => {
            setIsLoading(false);
        })
    }

    return <form onSubmit={handleSubmit}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="country-select-demo"
                        options={countries}
                        autoHighlight
                        onChange={(event, newValue) => {
                            setFormData({...formData, pais: newValue});
                        }}
                        required
                        fullWidth
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => {
                            const {key, ...optionProps} = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                    {...optionProps}
                                >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    {option.label}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecciona tu país"
                                required
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel id="etapa-educativa-label">Etapa Educativa</InputLabel>
                        <Select
                            labelId="etapa-educativa-label"
                            id="etapa_educativa"
                            label="Etapa Educativa"
                            onChange={(event) => setFormData({...formData, etapa_educativa: event.target.value})}
                            value={formData['etapa_educativa']}
                        >
                            <MenuItem value="Infantil">Infantil</MenuItem>
                            <MenuItem value="Primaria">Primaria</MenuItem>
                            <MenuItem value="Secundaria">Secundaria</MenuItem>
                            <MenuItem value="Formación Profesional">Formación Profesional</MenuItem>
                            <MenuItem value="Universidad">Universidad</MenuItem>
                            <MenuItem value="Otras Formaciones">Otras Formaciones</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel id="situacion-label">Situación</InputLabel>
                        <Select
                            labelId="situacion-label"
                            id="situacion"
                            label="Situación"
                            onChange={(event) => setFormData({...formData, situacion: event.target.value})}
                            value={formData['situacion']}
                        >
                            <MenuItem value="Docente con plaza o puesto estable">Docente con plaza o puesto
                                estable</MenuItem>
                            <MenuItem value="Interin@ o Sustitut@">Interin@ o Sustitut@</MenuItem>
                            <MenuItem value="Aspirante">Aspirante</MenuItem>
                            <MenuItem value="Estudiante de grado o universidad">Estudiante de grado o
                                universidad</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        onChange={handleFormFieldChange}
                        id="centro"
                        label="Centro educativo (si trabajas)"
                        fullWidth
                        variant="outlined"
                        size="large"
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel id="origen-label">¿Dónde nos conociste?</InputLabel>
                        <Select
                            labelId="origen-label"
                            id="origen"
                            label="¿Dónde nos conociste?"
                            onChange={(event) => setFormData({...formData, origen: event.target.value})}
                            value={formData['origen']}
                        >
                            <MenuItem value="Tiktok">Tiktok</MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Formación">Formación o cursos</MenuItem>
                            <MenuItem value="Recomendación">Recomendación de un compañero o amigo</MenuItem>
                            <MenuItem value="Instagram">Instagram</MenuItem>
                            <MenuItem value="Formación o curso en TICs">Formación o curso en TICs</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <LoadingButton variant='contained' type="submit" fullWidth
                                       loading={isLoading}>¡Empezar a usar 4Docentes!</LoadingButton>
                    </DialogActions>
                </Grid>
            </Grid>

            {isErrorMessageVisible && <ErrorMessage
                message={"No se ha podido actualizar tu perfil. Por favor, revisa tu conexión o intenta de nuevo más tarde."}
                disableErrorMessage={() => setIsErrorMessageVisible(false)}/>}
        </Box>
    </form>
}

export default MarketingProfile;

const countries = [
    {code: 'ES', label: 'España'},
    {code: 'AD', label: 'Andorra',},
    {code: 'AR', label: 'Argentina'},
    {code: 'BO', label: 'Bolivia',},
    {code: 'CL', label: 'Chile'},
    {code: 'CO', label: 'Colombia'},
    {code: 'CR', label: 'Costa Rica',},
    {code: 'CU', label: 'Cuba'},
    {code: 'EC', label: 'Ecuador',},
    {code: 'SV', label: 'El Salvador'},
    {code: 'GT', label: 'Guatemala',},
    {code: 'HN', label: 'Honduras',},
    {code: 'MX', label: 'Mexico'},
    {code: 'NI', label: 'Nicaragua',},
    {code: 'PA', label: 'Panamá'},
    {code: 'PY', label: 'Paraguay'},
    {code: 'PE', label: 'Peru'},
    {code: 'PR', label: 'Puerto Rico'},
    {code: 'DO', label: 'República Dominicana',},
    {code: 'UY', label: 'Uruguay'},
    {code: 'VE', label: 'Venezuela'},
];

