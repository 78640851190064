import React, {useState} from "react";
import {FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Select, Switch} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import AIService from "../../../services/AIService";
import TextField from "@mui/material/TextField";
import {Alert, LoadingButton} from "@mui/lab";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";
import RubricDetails from "./RubricDetails";
import MenuItem from "@mui/material/MenuItem";
import RubricTable from "./RubricTable";

const RubricForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleAPIError, handleSuccessMessage} = useOutletContext();
    const [level, setLevel] = useState("");
    const [subject, setSubject] = useState("");
    const [nivelesDeLogro, setNivelesDeLogro] = useState(5);
    const [numeroCriterios, setNumeroCriterios] = useState(3);
    const [descripcion, setDescripcion] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [criterios, setCriterios] = useState(["", "", ""]);
    const [json, setJson] = useState(null);
    const [userWantsToAddCriterio, setUserWantsToAddCriterio] = useState(false);


    function sendForm() {
        setLoading(true);

        const formData = {
            "level": level,
            "subject": subject,
            "nivelesDeLogro": nivelesDeLogro,
            "numeroCriterios": numeroCriterios,
            "description": descripcion,
            "json": true,
        }

        if (criterios[0] !== "") {
            formData["criterios"] = criterios.join(", ");
        }

        generateContent(formData);
    }

    function generateContent(formData) {
        setJson(null);
        setOutput('');
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })
        AIService.generateRubric(formData).then(response => {
            try {
                const jsonResponse =JSON.parse(response.data.text);
                setJson(jsonResponse);
            } catch (error) {
                setOutput(response.data.text);
            }
                setLoading(false);
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).catch(error => {
            handleAPIError(error);
            setLoading(false);
        }).finally(() => setLoading(false));
    }

    function handleNumeroCriteriosChange(e) {
        const oldNumeroCriterios = numeroCriterios;
        const newNumeroCriterios = e.target.value;

        setNumeroCriterios(newNumeroCriterios);

        if (newNumeroCriterios < oldNumeroCriterios) {
            setCriterios(criterios.slice(0, newNumeroCriterios));
        } else if (newNumeroCriterios > oldNumeroCriterios) {
            const newCriterios = [...criterios];
            for (let i = oldNumeroCriterios; i < newNumeroCriterios; i++) {
                newCriterios.push("");
            }
            setCriterios(newCriterios);
        }
    }

    function handleCriterioChange(e, criterio) {
        const newCriterios = [...criterios];
        newCriterios[criterio - 1] = e.target.value;
        setCriterios(newCriterios);
    }

    function saveCurrentRubric() {
        setLoading(true);
        const rubricToSend = {
            "level": level,
            "subject": subject,
            "markdown": output,
            "nivelesDeLogro": nivelesDeLogro,
            "numeroCriterios": numeroCriterios,
        }
        AIService.saveRubric(rubricToSend).then(response => {
                handleSuccessMessage("¡Rúbrica guardada correctamente!")
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setLoading(false)
        });
    }

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            {/*<Grid item xs={12}>
                <Button fullWidth variant="contained" color="info" href="/ia/mis-rubricas" startIcon={<ArticleIcon/>}>Ver
                    mis Rúbricas guardadas</Button>
            </Grid>*/}
            <Grid item xs={12}>
                <Alert severity="info">Esta nueva IA se encuentra en fase de pruebas y lanzaremos mejoras continuas en
                    los próximos días. Si tienes feedback o sugerencias acerca de su funcionamiento, por favor háznoslo
                    saber a través de Whatsapp.</Alert>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label="Nivel" variant="outlined" placeholder="1º Primaria"
                           onChange={(e) => setLevel(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label="Área" variant="outlined" placeholder="Matemáticas"
                           onChange={(e) => setSubject(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label="Descripción de la actividad" multiline maxRows={4} minRows={2}
                           variant="outlined"
                           placeholder="Describe la actividad que quieres evaluar. Cuáles son los objetivos que se van a alcanzar, cómo se va a llevar a cabo, etc."
                           onChange={(e) => setDescripcion(e.target.value)}/>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="niveles-de-logro">Indicadores de logro (columnas)</InputLabel>
                    <Select
                        labelId="niveles-de-logro"
                        id="select-niveles"
                        value={nivelesDeLogro}
                        label="Indicadores de logro (columnas)"
                        onChange={(e) => setNivelesDeLogro(e.target.value)}
                    >
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="numero-de-criterios">Número de criterios (filas)</InputLabel>
                    <Select
                        labelId="numeros-de-criterios"
                        id="select-criterios"
                        value={numeroCriterios}
                        label="Número de criterios (filas)"
                        onChange={handleNumeroCriteriosChange}
                    >
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel control={<Switch onChange={() => setUserWantsToAddCriterio(!userWantsToAddCriterio)}/>}
                                      label="Quiero proporcionar yo los criterios"/>
                </FormGroup>
            </Grid>
            {userWantsToAddCriterio &&Array.from({length: numeroCriterios}, (_, i) => i + 1).map(criterio => (
                <Grid item xs={12} key={criterio}>
                    <TextField fullWidth label={`Criterio ${criterio}`} variant="outlined"
                               onChange={(e) => handleCriterioChange(e, criterio)}/>
                </Grid>
            ))}
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={sendForm}>¡Generar rúbrica!</LoadingButton>
            </Grid>
            {/*output && <Grid item xs={12} sm={6} md={4}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={saveCurrentRubric} startIcon={<SaveIcon/>}>Guardar en Mis
                    Rúbricas</LoadingButton>
            </Grid>*/}
            <Grid item xs={12}>
                {output && <RubricDetails rubric={output}/>}
                {json && <RubricTable rubric={json}/>}
            </Grid>
        </Grid>
    </>
}

export default RubricForm;