import GenericModal from "../genericui/modals/GenericModal";
import React, {useEffect} from "react";
import MarketingProfile from "./MarketingProfile";
import MarketingService from "../../services/MarketingService";

const ProfilePopup = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        MarketingService.getMarketingProfile().then((data) => {
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                setIsOpen(true);
            }
        });
    }, [])

    if (isOpen) {
        return <GenericModal title="¡Cuéntanos sobre ti para que podamos personalizar tu experiencia!">
            <MarketingProfile onSave={() => setIsOpen(false)}/>
        </GenericModal>
    }
    return <></>
}

export default ProfilePopup;