import api from "./Api";


class MarketingService {
    getMarketingProfile() {
        return api.get('/marketing/profile');
    }

    createMarketingProfile(formData) {
        return api.post('/marketing/profile', formData);
    }
}

export default new MarketingService();
