import {Box, InputBase, Paper, Popper} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {
    DataGrid,
    GridCellModes,
    GridPrintExportMenuItem,
    GridToolbarContainer,
    useGridApiContext
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {exportExcel} from "../../classrooms/evaluation/presenters/ExcelPresenter";
import {Download} from "@mui/icons-material";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridPrintExportMenuItem/>
        </GridToolbarContainer>
    );
}

function EditTextarea(props) {
    const {id, field, value, colDef, hasFocus} = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState();
    const [inputRef, setInputRef] = React.useState(null);
    const apiRef = useGridApiContext();

    React.useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el) => {
        setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue(
                {id, field, value: newValue, debounceMs: 200},
                event,
            );
        },
        [apiRef, field, id],
    );

    return (
        <div style={{position: 'relative', alignSelf: 'flex-start'}}>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement="bottom-start">
                    <Paper elevation={1} sx={{p: 1, minWidth: colDef.computedWidth}}>
                        <InputBase
                            multiline
                            rows={8}
                            value={valueState}
                            sx={{textarea: {resize: 'both'}, width: '100%', height: '100%'}}
                            onChange={handleChange}
                            inputRef={(ref) => setInputRef(ref)}
                        />
                    </Paper>
                </Popper>
            )}
        </div>
    );
}

const FIRST_COLUMN = {
    id: 'name',
    field: "name",
    headerName: "",
    flex: 1,
    minWidth: 150,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
}

const RubricTable = ({rubric}) => {
    const [cellModesModel, setCellModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const columns = [FIRST_COLUMN].concat(rubric.columns.map((column) => ({
        id: "column" + column.score,
        field: "column" + column.score,
        headerName: column.title + " (" + column.score + "pts.)",
        flex: 1,
        minWidth: 150,
        editable: true,
        renderEditCell: (params) => <EditTextarea {...params} />,
    })));

    useEffect(() => {
        const finalRows = [];
        rubric.rows.forEach((row) => {
            const currentRow = {id: row.name, name: row.name};
            row.achievement_levels.forEach((achievement_level) => {
                currentRow["column" + achievement_level.score] = achievement_level.description;
            });
            finalRows.push(currentRow);
        })

        setRows(finalRows);
    }, [rubric])

    const handleCellClick = useCallback((params, event) => {
        if (!params.isEditable) {
            return;
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: {mode: GridCellModes.View},
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({...acc, [field]: {mode: GridCellModes.View}}),
                        {},
                    ),
                    [params.field]: {mode: GridCellModes.Edit},
                },
            };
        });
    }, []);

    const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);

    return (
        <>
            <Box>
                <Button
                    onClick={() => exportExcel(rows, columns, "Rubrica 4Docentes")}
                    startIcon={<Download/>}>Descargar a Excel</Button>
            </Box>
            {rubric && <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                disableSelectionOnClick
                disableRowSelectionOnClick
                hideFooter
                slots={{
                    toolbar: CustomToolbar,
                }}
                localeText={{
                    toolbarExportPrint: "🖨️ Imprimir",
                }}
                slotProps={{
                    toolbar: {
                        printOptions: {
                            hideToolbar: true,
                        }
                    }
                }}
                disableColumnMenu
                disableColumnSorting
                showCellRightBorder
                sx={{
                    "& .MuiDataGrid-cell": {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                    },
                    "& .MuiDataGrid-columnHeader": {
                        border: 1,
                        borderLeft: 0,
                        borderTop: 0,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: 1,
                    },
                }}
            />}
        </>
    );
};

export default RubricTable;