import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Checkbox,
    createFilterOptions,
    Divider, FormControl,
    FormControlLabel,
    FormGroup,
    Grid, Select,
    Switch
} from "@mui/material";
import SDADetails from "./SDADetails";
import SaveIcon from '@mui/icons-material/Save';
import AIService from "../../../services/AIService";
import {useOutletContext} from "react-router-dom";
import Button from "@mui/material/Button";
import ArticleIcon from '@mui/icons-material/Article';
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";
import {COMUNIDADES, LEVELS} from "../../classrooms/utils";
import SubjectService from "../../../services/SubjectService";
import Typography from "@mui/material/Typography";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import EvaluationService from "../../../services/EvaluationService";
import MenuItem from "@mui/material/MenuItem";

const METODOLOGIAS = [
    "Método Tradicional",
    "Aprendizaje Basado en Proyectos (ABP)",
    "Aprendizaje Cooperativo",
    "Método Montessori",
    "Aprendizaje Basado en Problemas (ABP)",
    "Flipped Classroom (Aula Invertida)",
    "Método Waldorf",
    "Método Reggio Emilia",
    "Aprendizaje Personalizado",
    "Gamificación",
    "Aprendizaje Experiencial",
    "Aprendizaje Servicio",
    "Método de la Clase Magistral",
    "Aprendizaje Basado en Competencias (ABC)",
    "Aprendizaje Autónomo",
];

const PRODUCTOS_FINALES = [
    "Juego educativo",
    "Investigación académica",
    "Presentación oral",
    "Video documental",
    "Gráfico informativo",
    "Programa de radio",
    "Libro digital",
    "Cómic",
    "Actuación",
    "Publicación",
    "Video instructivo",
    "Anuncio",
    "Guía",
    "Discusión",
    "Montaje",
    "Melodía",
    "Texto teatral",
    "Juego de interpretación",
    "Presentación multimedia",
    "Pintura mural",
]

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

export default function SDAForm(props) {
    const [level, setLevel] = useState("");
    const [subject, setSubject] = useState("");
    const [otros, setOtros] = useState("");
    const [metodologia, setMetodologia] = useState("");
    const [temporalizacion, setTemporalizacion] = useState("");
    const [atencionDiversidad, setAtencionDiversidad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleSuccessMessage, handleAPIError} = useOutletContext();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [lomloeMode, setLomloeMode] = useState(false);
    const [region, setRegion] = useState(COMUNIDADES[0]);
    const [subjects, setSubjects] = useState([]);
    const [criterio, setCriterio] = useState("Criterios");
    const [curriculo, setCurriculo] = useState({});
    const [selectedCriterios, setSelectedCriterios] = useState([]);
    const [producto, setProducto] = useState("");

    useEffect(() => {
        if (region.id && level.id && lomloeMode) {
            SubjectService.getLomloeSubjectsByRegionAndLevel(region.id, level.id).then(response => setSubjects(response.data))
                .catch(() => setSubjects([]));
        }
    }, [region, level, lomloeMode]);

    const refreshCriterios = (newSubject) => {
        if (newSubject) {
            setSelectedCriterios([]);
            EvaluationService.getLomloeCriteriosBySubject(newSubject.id).then(response => setCurriculo(response.data));
        }
    }

    function sendForm() {
        setLoading(true);
        let criteriosList = null;
        if (curriculo[criterio]) {
            criteriosList = curriculo[criterio].filter(element => selectedCriterios.includes(element.id)).map(element => element.label + " - " + element.title);
        }

        const variablesDict = {
            "level": level.name ? level.name : level,
            "subject": subject.name ? subject.name : subject,
            "metodologia": metodologia,
            "temporalizacion": temporalizacion,
            "diversidad": atencionDiversidad,
            "otros": otros,
            "producto": producto,
            "lomloe": criteriosList ? "La SDA trabajará los siguientes " + criterio + ": " + criteriosList.join("; ") + ". Inclúyelos todos ellos en el contenido de la SDA en su propia sección llamada " + criterio + " LOMLOE." : null,
        }

        generateContent(variablesDict);
    }

    function generateContent(variablesDict) {
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })

        AIService.generateSDA(variablesDict).then(response => {
                setOutput(response.data.text);
                setLoading(false);
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).catch(error => {
            handleAPIError(error);
            setLoading(false);
        }).finally(() => setLoading(false));
    }

    function saveCurrentSDA() {
        setLoading(true);
        const sdaToSend = {
            "level": level,
            "subject": subject,
            "metodologia": metodologia,
            "temporalizacion": temporalizacion,
            "diversidad": atencionDiversidad,
            "producto": producto,
            "otros": otros,
            "markdown": output,
        }
        AIService.saveSDA(sdaToSend).then(response => {
            handleSuccessMessage("¡SDA guardada correctamente!")
        }).finally(() => setLoading(false))
    }

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="info" href="/ia/mis-sdas" startIcon={<ArticleIcon/>}>Ver
                    mis SDAs guardadas</Button>
            </Grid>
            <Grid item xs={12} sx={{mx: 2}}>
                <FormGroup>
                    <FormControlLabel control={<Switch onChange={() => setLomloeMode(!lomloeMode)}/>}
                                      label="Modo LOMLOE"/>
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Typography>Introduce los datos de tu clase:</Typography>
            </Grid>
            {!lomloeMode ? <>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Nivel" variant="outlined" placeholder="1º Primaria"
                               onChange={(e) => setLevel(e.target.value)}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Área" variant="outlined" placeholder="Matemáticas"
                               onChange={(e) => setSubject(e.target.value)}/>
                </Grid>
            </> : <>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_region"
                        options={COMUNIDADES}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setRegion(value)}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Comunidad Autónoma"
                                                            variant="outlined"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_level"
                        options={LEVELS}
                        disabled={!region}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setLevel(value)}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Nivel"
                                                            variant="outlined"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="lomloe_subject"
                        options={subjects}
                        disabled={!subjects || subjects.length === 0}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                            setSubject(value);
                            refreshCriterios(value);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} required label="Asignatura"
                                                            variant="outlined"/>}
                    />
                </Grid>
            </>}
        </Grid>
        {lomloeMode && <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Typography>Selecciona el currículo que quieres trabajar:</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Select
                        id="select"
                        value={criterio}
                        onChange={(event) => setCriterio(event.target.value)}
                    >
                        <MenuItem value={"Criterios"}>Criterios</MenuItem>
                        <MenuItem value={"Competencias Específicas"}>Competencias Específicas</MenuItem>
                        <MenuItem value={"Competencias Clave"}>Competencias Clave</MenuItem>
                        <MenuItem value={"Descriptores"}>Descriptores</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="criterios"
                    name='criterios'
                    disabled={!curriculo[criterio]}
                    multiple
                    freeSolo
                    fullWidth
                    onChange={(event, values) => setSelectedCriterios(values.map(element => element.id))}
                    disableCloseOnSelect
                    options={curriculo[criterio] || []}
                    filterOptions={createFilterOptions({
                        stringify: (option) => option.label + " " + option.description,
                        ignoreAccents: true,
                        ignoreCase: true,
                    })}
                    renderOption={(props, option, {selected}) => (
                        <>
                            <li {...props}>
                                <Grid container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                      columns={16}
                                >
                                    <Grid item xs={2}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                        />
                                    </Grid>
                                    <Grid item xs={14}>
                                        <Typography
                                            component='div'><strong>{option.label}</strong>: {option.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                            <Divider/>
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={!curriculo[criterio]}
                            margin="dense"
                            fullWidth
                            id="criteria"
                            label={criterio + " LOMLOE"}
                            size='medium'
                            name='criteria'
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
        </Grid>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Typography>Introduce el resto de detalles:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    id="free-solo-metodologia"
                    freeSolo
                    options={METODOLOGIAS}
                    onInputChange={(e, value) => setMetodologia(value)}
                    renderInput={(params) => <TextField fullWidth label="Metodología" variant="outlined" {...params}/>}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    id="free-solo-producto-final"
                    freeSolo
                    options={PRODUCTOS_FINALES}
                    onInputChange={(e, value) => setProducto(value)}
                    renderInput={(params) => <TextField fullWidth label="Producto final"
                                                        variant="outlined" {...params}/>}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Temporalización" variant="outlined" placeholder="3 sesiones de 50 minutos"
                           onChange={(e) => setTemporalizacion(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Checkbox onChange={(e) => setAtencionDiversidad(e.target.checked)}/>}
                                  label="Atención a la diversidad"/>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label="Otras indicaciones" multiline maxRows={4} minRows={2} variant="outlined"
                           placeholder="Escribe algunas indicaciones como temática, comunidad autónoma, ideas, contexto..."
                           onChange={(e) => setOtros(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={sendForm}>¡Generar!</LoadingButton>
            </Grid>
            {output && <Grid item xs={12} sm={6} md={4}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={saveCurrentSDA} startIcon={<SaveIcon/>}>Guardar en MIS SDAs</LoadingButton>
            </Grid>}
            <Grid item xs={12}>
                {output && <SDADetails sda={output}/>}
            </Grid>
        </Grid>
    </>
}